'use strict';

import Tools from './tools.js';
import CountdownTimer from './countdown-timer.js';

export default class RateLimiter {
    initialize() {
        const instance = this;
        this.tools = new Tools();

        const elements = document.querySelectorAll('.js-rate-limiter');
        elements.forEach(element => {
            instance.check(element);
        });
    }

    removeCounter(container) {
        const oldTimers = container.querySelectorAll('#timer');
        if (oldTimers.length > 0) {
            oldTimers.forEach(oldTimer => {
                oldTimer.remove();
            })
        }
    }

    addCounter(container, type) {
        this.removeCounter(container);
        const element = document.createElement('p');
        element.id = 'timer';
        element.classList.add('js-rate-limiter');
        element.dataset.type = type;
        element.innerHTML = 'Du bist gesperrt für <span class="time"></span>.';

        container.append(element);
        this.check(element);
    }

    formToggleEnabled(form, enabled) {
        if (!form) {
            return;
        }

        const formTypes = [
            'button',
            'select',
            'input',
            'textarea',
            'button',
        ];
        formTypes.forEach(type => {
            const fields = form.querySelectorAll(type);
            if (fields.length > 0) {
                fields.forEach(field => {
                    field.disabled = !enabled;
                });
            }
        });
    }

    async check(element) {
        const instance = this;
        const type = element.dataset.type;
        const typeToUrl = {
            picture: '/picture/rate/status',
            pictureComment: '/picture/comment/rate/status'
        };

        const form = element.closest('form');
        const timerBlocked = new CountdownTimer(0, element, true, null);
        const rateLimitTime = await this.checkRateLimit(form, timerBlocked, typeToUrl[type])
        timerBlocked.setOnComlete((self) => {
            instance.formToggleEnabled(form, true);
        });
    }

    async checkRateLimit(form, timer, path) {
        const instance = this;
        return await fetch(path, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        }).then(response => {
            if (!response.ok) {
                throw new Error('Netzwerk Antwort war nicht in Ordnung!');
            }
            return response.json();
        }).then(data => {
            if (data.error === 0) {
                if (data.rate && data.rate.retryAfter && data.rate.retryAfter > 0) {
                    instance.formToggleEnabled(form, false);
                    timer.setTimer(data.rate.retryAfter);
                    timer.start();

                    return data.rate.retryAfter;
                } else {
                    instance.formToggleEnabled(form, true);
                    timer.stop();
                }
            } else {
                throw new Error('Es ist ein unbekannter Fehler aufgetreten!');
            }
            return 0;
        }).catch(error => {
            console.error('Error:', error);
            return 0;
        });
    }
}
