'use strict';

/**
 * const timerBlocked = new CountdownTimer(0, document.getElementById('timer'), true, null);
 * timerBlocked.setTimer(data.waitTime);
 * timerBlocked.setOnComlete((self) => {console.log('DONE');});
 * timerBlocked.start();
 * <p id="timer"></p>
 * <p id="timer">You are blocked for <span class="time"></span>.</p>
 */
export default class CountdownTimer {
  constructor(duration, element, humanReadable, onComplete = null) {
    this.duration = duration;
    this.element = element;
    this.elementInnerHTML = this.element.innerHTML;
    this.element.innerHTML = '';
    this.humanReadable = humanReadable;
    this.onComplete = onComplete;
    this.remainingTime = duration;
    this.timerId = null;
  }

  start() {
    if (this.timerId || this.remainingTime <= 0) {
        this.stop();
    }
    this.element.innerHTML = this.elementInnerHTML;
    this.elementTime = this.element.querySelector('.time') || null;
    this.updateElement();

    this.timerId = setInterval(() => {
      this.remainingTime--;
      this.updateElement();

      if (this.remainingTime <= 0) {
        this.stop();
        if (this.onComplete) {
            this.onComplete(this);
        }
      }
    }, 1000);
  }

  stop() {
    if (this.timerId) {
      this.element.innerHTML = '';
      clearInterval(this.timerId);
      this.timerId = null;
    }
  }

  setTimer(newDuration) {
    newDuration = isNaN(newDuration) ? 0 : newDuration;
    this.duration = newDuration;
    this.remainingTime = newDuration;
    this.updateElement();
  }

  setOnComlete(onComplete = null) {
    this.onComplete = onComplete;
  }

  updateElement() {
    const text = this.humanReadable ? this.getHumanReadableTime(this.remainingTime) : this.remainingTime;
    if (this.elementTime) {
        this.elementTime.textContent = text;
    } else {
        this.element.textContent = text;
    }
  }

  getHumanReadableTime(time) {
    const units = [
        {label: 'year', seconds: 31536000},
        {label: 'day', seconds: 86400},
        {label: 'hour', seconds: 3600},
        {label: 'minute', seconds: 60},
        {label: 'second', seconds: 1}
    ];

    let text = '';
    for (const {label, seconds} of units) {
        const value = Math.floor(time / seconds);
        if (value > 0 || text) {
            text += `${value} ${label}${value > 1 ? 's' : ''} `;
        }
        time %= seconds;
    }
    return text.trim();
  }
}
