'use strict';

import {Editor} from './ckeditor';
import RateLimiter from './rate-limiter.js';

export default class Picture {
    initialize(editor) {
        const pictureNew = document.getElementById('picture-new');
        if (pictureNew) {
            this.initializePictureNew(editor, pictureNew);
        }

        const pictureEdit = document.getElementById('picture-edit');
        if (pictureEdit) {
            this.initializePictureEdit(editor, pictureEdit);
        }
    }

    initializePictureNew(editor, element) {
        const instance = this;
        const form = element.querySelector('form');
        const editorElement = form.querySelector('#description');

        if (!editor) {
            editor = new Editor();
        }
        const editorInstance = editor.initializeEditor(editorElement, 'minimal', {
            characterMax: 500
        });

        form.addEventListener('submit', () => {
            return false;
        });

        const path = form.dataset.hasOwnProperty('path') ? form.dataset.path : '';
        const buttonSubmit = form.querySelector('button[type=submit]');
        buttonSubmit.addEventListener('click', (event) => {
            event.preventDefault();
            instance.addPicture(element, buttonSubmit, path, editorInstance);
        });
    }

    initializePictureEdit(editor, element) {
        const instance = this;
        const form = element.querySelector('form');
        const editorElement = form.querySelector('#description');

        if (!editor) {
            editor = new Editor();
        }
        const editorInstance = editor.initializeEditor(editorElement, 'minimal', {
            characterMax: 500
        });
    }

    async addPicture(container, buttonSubmit, url, editorInstance) {
        const form = container.querySelector('form');
        buttonSubmit.disabled = true;

        const description = await editorInstance.then((editor) => {
            return editor.getData();
        });

        const formData = new FormData();
        formData.append('_token', form.querySelector('[name="_token"]').value);
        formData.append('category', form.querySelector('#category').value);
        formData.append('keywords', form.querySelector('#keywords').value);
        formData.append('file', form.querySelector('#file').files[0]);
        formData.append('description', description);

        fetch(url, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Netzwerk Antwort war nicht in Ordnung!');
            }
            return response.json();
        }).then(data => {
            if (!data.hasOwnProperty('success') || data.success !== true) {
                const errorMessage = data.hasOwnProperty('error') ? data.error : 'Es ist ein unbekannter Fehler aufgetreten!';

                if (data.hasOwnProperty('rate') && data.rate.hasOwnProperty('retryAfter') && data.rate.retryAfter > 0) {
                    const rateLimiter = new RateLimiter();
                    rateLimiter.addCounter(form, 'picture');
                    toastr.error('Bild wurde nicht hinzugefügt!<br>' + errorMessage, '', {'timeOut': data.rate.retryAfter * 1000});
                    return;
                } else if (data.hasOwnProperty('redirect')) {
                    const redirectSeconds = 5;
                    toastr.warning(`${errorMessage}<br>Weiterleitung in ${redirectSeconds} Sekunden!`, '', {'timeOut': redirectSeconds * 1000});
                    setTimeout(() => {
                        location.href = data.redirect;
                        buttonSubmit.disabled = false;
                    }, redirectSeconds * 1000);
                    return;
                } else {
                    throw new Error(errorMessage);
                }
            }

            editorInstance.then((editor) => {
                return editor.setData('');
            });

            const redirectSeconds = 3;
            setTimeout(() => {
                toastr.success('Bild gespeichert', '', {'timeOut': redirectSeconds * 1000});
                location.href = data.redirect;
                buttonSubmit.disabled = false;
            }, redirectSeconds * 1000);
        }).catch(error => {
            toastr.error('Bild wurde nicht hinzugefügt!<br>' + error.toString(), '', {'timeOut': 0});
            console.error('Error:', error);
            buttonSubmit.disabled = false;
        // }).finally(() => {
            // buttonSubmit.disabled = false;
        });
    }

    updateShow(element, url, extId) {
        let instance = this;
        let show = element.querySelector('.show-comment');

        if (show) {
            fetch(url + '/show?extId=' + extId, {
                method: 'GET',
                headers: {
                    'Accept': 'text/html',
                },
            }).then(response => response.text()).then(data => {
                show.innerHTML = data;
                instance.scrollToComment(element);
            });
        }
    }

    scrollToComment(element, extId) {
        let scrollTop = 0;
        let scrollElement = window;

        // Get extId from new comment
        if (typeof extId === 'undefined') {
            let nodes = element.querySelectorAll('.show-comment .comment');
            extId = nodes[nodes.length - 1].getAttribute('data-id');
            extId = parseInt(extId, 10);
        }

        // Find comment and scroll
        let commentItem = element.querySelector('.show-comment .comment-' + extId);
        if (commentItem) {
            scrollTop = commentItem.offsetTop - 70; // top - headerNavigation

            let fancyboxSlideElement = commentItem.closest('.fancybox__slide');
            if (fancyboxSlideElement) {
                scrollElement = fancyboxSlideElement;
                scrollTop = commentItem.offsetTop;
            }

            scrollElement.scrollTo({
                top: scrollTop,
                behavior: 'smooth'
            });
        }
    }
}
